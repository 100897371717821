@import '../colors.scss';
.upsert-booking {

  .admin-calendar-override {
    margin-top: 10px;
    label {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 0px;
    }
    input {
      width: 20px;
      height: 20px;
      margin-left: 10px;
    }
  }

  .checkout {
    display: inline-grid;
    .checkout-title {
      text-align: center;
      font-weight: bold;
      font-size: 20px;
      margin-bottom: .5rem;
      margin-top: 30px;
    }

    .checkout-message {
      font-size: 20px;
      display: inline-grid;
      text-align: center;
      .checkout-total {
        text-align: center;
        display: inline-flex;
        margin: 0px auto 0px auto;
        .checkout-price {
          text-align: center;
          color: $primary-color;
          margin: 0px 5px 0px 5px;
        }
      }
    }

    .btn {
      margin: 30px 0px 30px 0px;
      span {
        margin-right: 10px;
      }
    }
  }

  .addons {
    text-align: left;
    margin-top: 30px;
    .complimentary-addons {
      text-align: center;
    }
    .addons-title {
      text-align: center;
      font-weight: bold;
      font-size: 20px;
      margin-bottom: .5rem;
    }
    .addon-item {
      font-size: 14px;
      margin-bottom: 15px;
      .addon-top-level {
        b {
          margin-left: 10px;
        }
        input {
          margin-right: 10px;
        }
      }
      .addon-subtext {
        font-size: 12px;
        padding-left: 25px;
      }
    }
  }

  .add-new-address {
    color: $primary-color;
  }

  .address-selection {
    max-width: 400px;
    margin: 30px auto 10px auto;
    label {
      font-weight: bold;
      font-size: 20px;
    }
  }

  hr.mobile-selection-divider {
    width: 75%;
  }

  .booking-selection-display {
    margin: 30px 0px 30px 0px;
    .booking-selection-display-title {
      font-weight: bold;
      font-size: 20px;
      margin-bottom: 15px;
    }
    .booking-selection-display-value {
      font-size: 17px;
      margin-bottom: 15px;
    }
    .clear-date-selection {
      color: $primary-color;
      font-size: 12px;
      &:hover {
        text-decoration: underline !important;
      }
    }

  }
}
