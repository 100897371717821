.auth-container {
  width: 80%;
  margin: 30px auto 30px auto;
  .basic-form {
    margin-bottom: 30px;
  }

  .login-option {
    text-decoration: underline;
    font-weight: bold;
    padding-bottom: 20px;
  }
}
