@import './colors.scss';

.credit-cards-container {
  max-width: 400px;
  margin: 30px auto 10px auto;
  .credit-cards-section-title {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: .5rem;
  }

  .credit-card-selection-dropdown {
    margin-bottom: 10px;
  }

  .btn.first-credit-card-entry {
    margin-top: 10px;
  }
}

.add-new-credit-card {
  color: $primary-color;
}

.new-credit-card-modal-container {
  .buttons {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .form-error-label {
    margin-bottom: 10px;
    color: $form-error;
    font-size: 12px;
  }
}

.credit-card-disclaimer {
  color: $secondary-color;
  width: 80%;
  margin: auto;
  padding-top: 10px;
}
