.user-updater-modal-container {
  .user-updater-field-group {
    label {
      min-width: 100px;
      margin-top: 20px;
      font-weight: bold;
    }
    textarea { width: 100% }
    input { width: 100% }
  }

  button {
    margin-top: 20px;
  }
}
