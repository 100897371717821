.user-dashboard-subscriptions {
  margin-bottom: 50px;
  .user-dashboard-subscription {
    p {
      margin: 10px 0px 0px 0px;
    }
  }

  .btn {
    margin: 0px 0px 0px   0px;
    width: 75%;
  }
}
