@import '../colors.scss';

.admin-dashboard-window-toggle {
  display: flex;
  margin: 20px 0px 30px 0px;
  h4 {
    padding-top: 5px;
  }
  img {
      height: 40px;
  }
}

.admin-daily-bookings-header {
  color: white;
  padding: 20px;
  margin: 5px;
  display: flex;

  &.enabled {
    background-color: $primary-color;
  }

  &.disabled {
    background-color: $light-grey;
  }

  .admin-daily-bookings-header-title {
    font-weight: bold;
    font-size: 20px;
    padding-left: 20px;
  }

  .admin-daily-bookings-header-description {
    margin-left: auto;
    font-weight: bold;
    font-size: 20px;
    padding-right: 20px;
  }
}


.admin-booking-actions {
  .admin-booking-actions-row {

  }
  img {
    margin: 2px;
    width: 30px;
  }
}

.admin-bookings-region-filters {
  display: flex;
  .admin-bookings-region-filter {
    margin: 5px;
    &.active {
      background-color: $primary-color;
    }
  }
}
