@import '../../colors.scss';

.basic-form {
  margin-top: 20px;
  button {
    text-align: center;
    width: 100%;
  }

  .spinner-border {
    margin-right: 10px;
  }

  .form-error-label {
    margin-bottom: 10px;
    color: $form-error;
    font-size: 12px;
  }

  .form-field {
    margin-bottom: 30px;
  }
}
