@import './colors.scss';
.navbar {
  color: white !important;
  a {
    color: white !important;
  }

  .navbar-brand {
    img {
      width: 200px
    }
  }
}
.nav-placeholder {
  background-color: $primary-color;
  height: 50px;
}

.nav-footer-placeholder {
  background-color: $primary-color;
  position: absolute;
   bottom: 0;
   width: 100%;
   height: 2.5rem;
   margin: 0 auto;
}
