@import './colors.scss';

.booking-calendar {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  .booking-calendar-month {
    margin: 30px 0px 30px 0px;
    .booking-calendar-row-time-selection {
      padding: 10px;
      font-weight: 400;
      font-size: 1rem;
      select {
        margin-top: 5px;
        width: 70%;
        padding: 2px;
        border-radius: 5px;
        border-color: $light-grey;
        color: grey;
      }
    }
    .booking-calendar-row {
      display: flex;
      align-items: right;
      justify-content: center;
      .booking-calendar-day {
        min-width: 60px;
        width: 60px;
        height: 60px;
        padding-top: 15px;
        text-align: center;
        margin: 10px 10px 10px 10px;
        position: relative;
      }

      .booking-calendar-day.bookable-date {
        border-radius: 50%;
        text-align: center;

        .preview-text, .hover-text {
          position: absolute;
          margin-left: auto;
          margin-right: auto;
          left: 0;
          right: 0;
          text-align: center;

          .text-title {
            font-size: 17px;
            font-weight: bold;
          }

          .text-subtext {
            font-size: 25px;
            line-height: 0px;
          }

          .hover-text-subtext {
            font-size: 10px;
            line-height: 0px;
          }
        }

        .hover-text {
          visibility: hidden;
        }
      }

      .booking-calendar-day.bookable-date.available {
        color: #00B287;
        border: 1px solid #00B287;

        &:hover {
          background-color: #00B287;
          .hover-text {
            color: white;
            visibility: visible;
          }
          .preview-text {
            visibility: hidden;
          }
        }
      }

      .booking-calendar-day.bookable-date.available.selected {
        background-color: #00B287;
        color: white;
      }

      .booking-calendar-day.bookable-date.not-available {
        color: #C1C1C1;
        border: 1px solid #C1C1C1;
      }
    }
  }

  .booking-calendar-wday-titles {
    font-size: 20px;
    font-weight: bold;
    color: #00B287;
  }
}

.booking-calendar-time-selection {
  margin: 30px 0px 30px 0px;



  .booking-calendar-time-back-title {
    display: inline-flex;
    .booking-calendar-navigate-back {
      height: 40px;
      margin-right: 10px;
    }
  }

  .booking-calendar-time-selection-title {
    position: relative;
    .booking-calendar-navigate-back {
      position: absolute;
      left: 30px;
      height: 50px;
    }
  }
  .booking-calendar-time-slot {
    border: 1px solid #00B287;
    color: #00B287;
    padding: 10px;
    width: 200px;
    margin: 20px auto 20px auto;
    border-radius: 10px;

    &:hover {
      background-color: #00B287;
      color: white;
    }
  }
}
