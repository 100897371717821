.addon-update-management-modal {
  display: grid;
  .addon-update-title {
    text-align: center;
  }
  .addon-update-item {
    word-wrap: break-word !important;
    input {
      margin-right: 10px;
    }
    margin-top: 10px;
  }

  .btn {
    margin-top: 20px;
  }

  .addon-update-price {
    text-align: center;
    font-weight: bold;
  }
}
