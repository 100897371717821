@import 'colors.scss';

.user-dashboard {
  padding-bottom: 50px;
  tbody {
    font-size: 12px;
  }
  .welcome-text {
    .edit-user-action {
      width: 30px;
      margin-left: 10px;
    }
    text-align: left;
    font-size: 30px;
    margin: 20px 0px 20px;
  }
  .user-dashboard-bookings {
    margin-bottom: 50px;
    .user-dashboard-bookings-header {
      display: flex;
      justify-content: left;
      position: relative;
      margin-bottom: 10px;
      .right-option {
        position: absolute;
        right: 2%;
      }
    }
    .recurring {
      width: 100%;
    }
    .user-dashboard-addons {
      .btn {
        margin-top: 5px;
      }
    }
    .user-booking-actions {
      .btn {
        margin: 1px 1px;
      }
    }
  }

  .user-dashboard-subscriptions {
    .btn {
      margin-top: 10px;
    }
  }

  .user-dashboard-addresses {
    margin-bottom: 20px;
    .address-selection {
      max-width: 400px;
      margin: 0px auto 0px auto;
    }
  }

  .react-add-to-calendar {
    .react-add-to-calendar__wrapper {
      padding: .25rem .5rem;
      font-size: .875rem;
      line-height: 1.5;
      border-radius: .2rem;
      background-color: $primary-color;
      .react-add-to-calendar__button span {
        color: white;
      }
    }
    .react-add-to-calendar__dropdown {
      border: 1px solid #a8a8a8;
      background-color: #fff;
      text-align: left;
      ul {
        list-style: none;
        padding-inline-start: 10px;
      }
    }
  }

  .user-dashboard-referrals {
    .user-referral-code-sub-title {
      color: $light-grey;
      font-size: 12px;
      margin: 5px 0px 10px;
    }
  }
}
