@import './colors.scss';

a, a:not([href]) {
  color: $primary-color !important;
  cursor:pointer;
}

.underline {
  text-decoration: underline !important;
}

.btn-primary {
  background-color: $primary-color !important;
  border-color: $primary-color !important;;
}

.primary-color {
  color: $primary-color !important;
}

.btn-secondary{
  background-color: white !important;
  border-color: white !important;
  color: $primary-color !important;
}

.rotate-180 {
  transform: rotate(180deg);
}

.rotate-90 {
  transform: rotate(90deg);
}

.padding-top-10 {
  padding-top: 10px;
}

.margin-right-5 {
  margin-right: 5px;
}

.float-right {
  float: right;
}

.subtext {
  color: $light-grey;
  font-size: 12px;
}

.subtitle {
  color: $light-grey;
  font-size: 12px;
}
